import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Images from "../components/imageVar"
import SEO from "../components/seo"
import { cx } from "emotion"
import { Space, Flex, Typo } from "../styles"

import HeroSection from "../sections/hero"


const IntegrationsPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className={cx(Space.section)}>
      <h1>Deeploop works with your stack</h1>
      <h4>Connect applications to Deeploop to experience</h4>
    </div>
  </Layout>
)

export default IntegrationsPage
