import React from 'react'
import { cx } from 'emotion'
import { Space, Flex, Typo, Background } from '../styles'
import SignUp from '../components/forms/signup'
import { TickMark } from '../components/icons'
import { Row, Col } from "reactstrap"
import Image from "../components/imageVar"

import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import { Cone, Tetra } from "../components/shapes"

const Element = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bgimg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
          >
          <div className={cx(Space.hero,Typo.white)}>
            <div className={cx(Typo.teal)} style={{position:"absolute",right:-60,bottom:50,width:300,height:300,transform:"rotate(270deg)"}}><Cone /></div>
            <div className={cx(Typo.purple)} style={{position:"absolute",left:-60,top:50,width:300,height:300,transform:"rotate(270deg)"}}><Tetra /></div>
            
            <Row>
              <Col xs={12} sm={6}>
                <h1>Simpler, faster and better inbound sales</h1>
                <h4>Recover your marketing dollars by never missing a beat on your inbound sales.</h4>
                <SignUp />
              </Col>
            </Row>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(Element)`
  width: 100%;
  background-position: bottom right;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection